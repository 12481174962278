//== BOOTSTRAP
import { Popper } from '@popperjs/core'
import { Modal } from 'bootstrap'
window.Modal = Modal

// //== JQUERY
import $ from 'jquery'
window.$ = window.jQuery = $

// FANCYBOX
import { fancybox } from "components/fancybox";